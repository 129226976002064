<template>
  <nav
    v-if="hasBothFulfillmentOptions"
    class="flex bg-gray-brand-200 border border-gray-brand-500 p-1 rounded-lg mb-4"
    :class="showDeliveryFulfillmentOptionsFirst ? 'flex-row-reverse' : 'flex-row'"
  >
    <button
      v-if="hasPickupOptions"
      class="w-full rounded-lg py-4 text-sm font-medium leading-5 ring-white/60 ring-offset-2 ring-offset-brand-800 focus:outline-none focus:ring-2"
      :class="
        fulfillmentOptionsSection && showPickupFulfillmentMethodsSection
          ? 'bg-white text-brand-800 shadow'
          : 'text-brand-900 hover:bg-black/[0.12] hover:text-black'
      "
      @click="fulfillmentOptionsSection = 'pickup'"
    >
      <div class="flex flex-col items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 mx-auto mb-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
          />
        </svg>
        {{ pickupTabTitle }}
      </div>
    </button>
    <div class="w-2"></div>
    <button
      v-if="hasDeliveryOptions"
      class="w-full rounded-lg py-4 text-sm font-medium leading-5 ring-white/60 ring-offset-2 ring-offset-brand-800 focus:outline-none focus:ring-2"
      :class="
        fulfillmentOptionsSection && !showPickupFulfillmentMethodsSection
          ? 'bg-white text-brand-800 shadow'
          : 'text-brand-900 hover:bg-black/[0.12] hover:text-black'
      "
      @click="fulfillmentOptionsSection = 'delivery'"
    >
      <div class="flex flex-col items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6 mx-auto mb-1"
          fill="none"
          viewBox="0 0 50 50"
          stroke="currentColor"
        >
          <g>
            <path
              d="M4.955,39.75C4.351,40.687,4,41.803,4,43c0,3.313,2.687,6,6,6s6-2.687,6-6c0-0.414-0.042-0.818-0.122-1.209"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
          <g>
            <path
              d="M7.477,41.375C7.175,41.844,7,42.4,7,43c0,1.656,1.344,3,3,3s3-1.344,3-3c0-0.207-0.02-0.409-0.062-0.605"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
          <g>
            <path
              d="M36,43c0,3.313,2.687,6,6,6s6-2.687,6-6c0-1.503-0.553-2.877-1.467-3.93"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
          <path
            d="M39.783,40.391C40.51,41.324,41.262,42.214,42,43"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            d="M11.444,41.324C10.971,41.917,10.487,42.48,10,43"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            d="M34,44c1.659,0,0.833-1.843,5.983-3.961C45.688,37.693,49,40.343,49,37.721c0-1.785-2.879-4.679-8.583-4.679C35.892,33.042,32,36.574,32,41C32,43.74,33.19,44,34,44z"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            d="M3.24,28.6c0,0-2.24,3.174-2.24,7.4s12.072,6,22.143,6c3.417,0,6.304-0.307,8.69-0.839"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            d="M30.137,16.194C28.355,16.966,26.247,17.882,26,18c-1.641,0.788-0.539,2.359,1,2c1.276-0.297,3-2,3-2c2,5,5.351,19-6,19c-3.777,0-7.159,0.562-5-7c0,0-0.274-1.268-1.333-1.611"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            d="M40.65,32.474C40.508,31.977,40.29,31.484,40,31c-1.396-2.28-4.97-6.745-6.644-12.658"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            d="M37.51,15.825c0,1.412,0.127,2.556-2.345,2.556c-2.474,0-4.477-1.145-4.477-2.556c0-1.408,2.003-2.553,4.477-2.553C37.637,13.271,37.51,14.417,37.51,15.825z"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            d="M1,27.373c0,1.021,3.958,1.636,8.912,1.636c4.955,0,9.088-0.614,9.088-1.636l0,0C19,26.354,18.206,25,17.224,25H2.776C1.794,25,1,26.354,1,27.373L1,27.373z"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <circle cx="42" cy="43" r="3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        </svg>
        {{ deliveryTabTitle }}
      </div>
    </button>
  </nav>

  <Box v-if="fulfillmentOptionsSection" :is-loading="isLoading" class="relative mb-4">
    <div v-if="showPickupFulfillmentMethodsSection" class="h-64 w-full rounded-b-md overflow-hidden">
      <PickupMap
        :methods="possiblePickupFulfillmentMethods"
        @clicked-new-method="clickedNewMethod"
        :bottle="bottle"
        @show-loading="showLoading"
      />
    </div>

    <div
      v-fade-in
      v-if="showPickupFulfillmentMethodsSection"
      class="leading-5"
      :class="!showAllPickupOptions && 'max-h-[27rem] overflow-y-auto'"
    >
      <div v-for="(method, i) in possiblePickupFulfillmentMethods" class="border-gray-300 border-b">
        <FulfillmentMethodChoice
          :index="i + 1"
          :key="method.id"
          :method="method"
          :bottle="bottle"
          @clicked-new-method="clickedNewMethod"
          @show-loading="showLoading"
        />
      </div>
    </div>
    <div
      v-if="showPickupFulfillmentMethodsSection && possiblePickupFulfillmentMethods.length > 2 && !showAllPickupOptions"
      class="absolute bottom-0 w-full flex justify-center pb-2"
    >
      <SecondaryButton @click="showAllPickupOptions = true" class="ml-3 mt-3 rounded-full shadow-md" :size="'md'">
        Show all {{ possiblePickupFulfillmentMethods.length }} locations
        <template #right-icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
          </svg>
        </template>
      </SecondaryButton>
    </div>
    <div v-fade-in v-if="!showPickupFulfillmentMethodsSection">
      <div v-show="hasDeliveryAddresses" class="pt-4 pb-2 px-6 max-h-72 overflow-y-auto border-b">
        <fieldset>
          <div class="leading-5">
            <DeliveryAddressChoice
              v-for="deliveryAddress in possibleDeliveryAddresses"
              :key="deliveryAddress.id"
              :delivery-address="deliveryAddress"
              :bottle="bottle"
              @clicked-new-method="clickedNewMethod"
              @show-loading="showLoading"
              @delivery-address-change-clicked="deliveryAddressChangeClicked"
              @delivery-address-action-executed="deliveryAddressActionExecuted"
            />
          </div>
        </fieldset>
      </div>
      <AddDeliveryAddress
        :passed-delivery-address="selectedDeliveryAddressForEdit"
        :force-open="fulfillmentOptionsSection === 'delivery' && !hasDeliveryAddresses"
        :passed-bottle="bottle"
        @delivery-address-edited="deliveryAddressEdited"
        @delivery-address-edit-canceled="deliveryAddressEditCanceled"
      />
    </div>
  </Box>
</template>

<script>
import FulfillmentMethodChoice from '@/components/Checkout/FulfillmentMethodChoice'
import DeliveryAddressChoice from '@/components/Checkout/DeliveryAddressChoice'
import AddDeliveryAddress from '@/components/Checkout/AddDeliveryAddress'
import PickupMap from '@/components/Checkout/PickupMap'
import SecondaryButton from '@/components/Molecules/SecondaryButton.vue'

import Box from '@/components/Molecules/Box'
import mapRelationship from '../../utils/mapRelationship'

export default {
  name: 'FulfillmentSelectionModule',
  components: {
    Box,
    FulfillmentMethodChoice,
    DeliveryAddressChoice,
    AddDeliveryAddress,
    SecondaryButton,
    PickupMap
  },
  props: {
    initialFulfillmentSection: {
      type: String,
      required: false,
      default: null
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    passedBottle: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    selectedDeliveryAddressForEdit: undefined,
    showAllPickupOptions: false,
    fulfillmentOptionsSection: null
  }),
  computed: {
    bottle() {
      if (this.passedBottle && this.isMultibottleFunctionality()) {
        return this.passedBottle
      } else {
        return this.$store.getters.currentBottle
      }
    },
    store() {
      return mapRelationship(this.$store.state, this.bottle?.relationships?.store?.data)
    },
    possibleFulfillmentMethods() {
      // if this.store.attributes.fulfillment_method_order == 'alphabetical', then sort the methods by their .name attribute
      if (this.store?.attributes?.fulfillment_method_order == 'alphabetical') {
        return this.$store.getters
          .fulfillmentMethods(this.bottle?.relationships?.possible_fulfillment_methods?.data)
          .sort((x, y) => x.attributes.name.localeCompare(y.attributes.name))
          .sort((x, y) => y.attributes.eligible - x.attributes.eligible)
        // if this.store.attributes.fulfillment_method_order == 'upcoming-windows', then sort the methods by their first upcoming window
      } else if (this.store?.attributes?.fulfillment_method_order == 'upcoming-windows') {
        return (
          this.$store.getters
            .fulfillmentMethods(this.bottle?.relationships?.possible_fulfillment_methods?.data)
            // open up the fulfillment method and get the first upcoming window to compare
            .sort((x, y) => {
              const xFirstUpcomingWindow = x.attributes.upcoming_windows?.[0]
              const yFirstUpcomingWindow = y.attributes.upcoming_windows?.[0]
              if (xFirstUpcomingWindow && yFirstUpcomingWindow) {
                return xFirstUpcomingWindow.arrives_beginning_estimate.localeCompare(
                  yFirstUpcomingWindow.arrives_beginning_estimate
                )
              } else if (xFirstUpcomingWindow) {
                return -1
              } else if (yFirstUpcomingWindow) {
                return 1
              } else {
                return 0
              }
            })
        )
      } else {
        // if this.store.attributes.fulfillment_method_order == 'order', then sort the methods by their .order attribute
        return this.$store.getters
          .fulfillmentMethods(this.bottle?.relationships?.possible_fulfillment_methods?.data)
          .sort((x, y) => y.attributes.eligible - x.attributes.eligible)
      }
    },
    possiblePickupFulfillmentMethods() {
      return this.possibleFulfillmentMethods?.filter((x) => x?.attributes?.type === 'PickupFulfillmentMethod')
    },
    hasPickupOptions() {
      // return this.possiblePickupFulfillmentMethods?.length > 0
      return this.bottle?.attributes?.possible_fulfillment_method_types?.pickup?.type == 'pickup'
    },
    hasDeliveryOptions() {
      return this.bottle?.attributes?.possible_fulfillment_method_types?.delivery?.type == 'delivery'
    },
    hasBothFulfillmentOptions() {
      return this.hasPickupOptions && this.hasDeliveryOptions
    },
    showDeliveryFulfillmentOptionsFirst() {
      return this.store?.attributes?.show_delivery_fulfillment_options_first
    },
    pickupTabTitle() {
      return this.bottle?.attributes?.possible_fulfillment_method_types?.pickup?.title || 'Pickup'
    },
    deliveryTabTitle() {
      return this.bottle?.attributes?.possible_fulfillment_method_types?.delivery?.title || 'Delivery'
    },
    possibleDeliveryAddresses() {
      return (
        this.$store.getters
          .deliveryAddresses(this.bottle?.relationships?.possible_delivery_addresses?.data)
          // sort eligible first
          .sort((x, y) => y.attributes.eligible - x.attributes.eligible)
      )
    },
    hasDeliveryAddresses() {
      return this.possibleDeliveryAddresses && this.possibleDeliveryAddresses.length > 0
    },
    showPickupFulfillmentMethodsSection() {
      return this.hasPickupOptions && this.fulfillmentOptionsSection == 'pickup'
    }
  },
  methods: {
    deliveryAddressChangeClicked(address) {
      this.selectedDeliveryAddressForEdit = address
      this.$emit('deliveryAddressActionExecuted')
    },
    deliveryAddressActionExecuted() {
      this.$emit('deliveryAddressActionExecuted')
    },
    clickedNewMethod() {
      this.$emit('clickedNewMethod')
    },
    showLoading(value) {
      this.$emit('showLoading', value)
    },
    isMultibottleFunctionality() {
      const currentBottle = this.$store.getters.currentBottle
      return currentBottle && currentBottle.attributes.allow_multiple_days
    },
    deliveryAddressEdited() {
      this.selectedDeliveryAddressForEdit = undefined
      this.$emit('deliveryAddressEditedAndSelected')
    },
    deliveryAddressEditCanceled() {
      this.selectedDeliveryAddressForEdit = false
    }
  },
  created() {
    if (this.initialFulfillmentSection) {
      this.fulfillmentOptionsSection = this.initialFulfillmentSection
    } else if (!this.hasBothFulfillmentOptions) {
      if (this.hasPickupOptions) {
        this.fulfillmentOptionsSection = 'pickup'
      } else if (this.hasDeliveryOptions) {
        this.fulfillmentOptionsSection = 'delivery'
      }
    }
  }
}
</script>

<template>
  <!-- TODO(alicja): extract out redundant lookups -->
  <ProductContainer v-if="product" :key="product.id" :in-cart="submittableCartItemQuantity > 0">
    <ProductBody v-if="!isHeroExpanded">
      <ProductText @click="collapse">
        <ProductLabels :labels="$store.getters.labels(product.attributes.id)" />
        <ProductDescription
          :title="product.attributes.product_name"
          :collapsed="collapsed"
          :description="product.attributes.description"
        />
      </ProductText>
      <ProductAttachment
        v-if="$store.getters.attachments(product.attributes.id).length"
        :sources="$store.getters.attachments(product.attributes.id)"
        @click="handleExpandHero"
      />
    </ProductBody>
    <ProductNutritionalInformation v-if="!isHeroExpanded" :nutritional-information="nutritionalInformation" />
    <ProductAttachmentHero
      v-if="isHeroExpanded && $store.getters.attachments(product.attributes.id).length"
      :title="product.attributes.product_name"
      :sources="$store.getters.attachments(product.attributes.id)"
      @clicked="handleExpandHero"
    >
      <ProductLabels :labels="$store.getters.labels(product.attributes.id)" />
    </ProductAttachmentHero>
    <ProductFooter>
      <ProductNutritionalInformation
        class="my-3"
        v-if="isHeroExpanded"
        :nutritional-information="nutritionalInformation"
      />
      <ProductPricing
        :product="product"
        :submittable-cart-item="submittableCartItem"
        :category-product-store-pair="categoryProductStorePair"
      />
      <ProductBody v-if="isHeroExpanded && $store.getters.attachments(product.attributes.id).length">
        <ProductText @click="collapse">
          <ProductDescription title="" :collapsed="collapsed" :description="product.attributes.description" />
        </ProductText>
      </ProductBody>
      <ProductVariant
        v-for="v in variants"
        :key="v.id"
        :variant="v"
        :product="product"
        :submittable-cart-item="submittableCartItem"
        :open-tray="currentVariant === v.attributes.id"
        :category-product-store-pair="categoryProductStorePair"
        @open="setCurrentVariant"
      />
      <ProductDaySplit :product="product" :submittable-cart-item="submittableCartItem" />
      <div v-if="offerToCustomizeAnother" class="flex">
        <div class="w-full border-t bg-white text-brand-800 flex justify-end">
          <div class="leading-5 pt-4 pb-4 px-6 scrolling-touch text-sm text-center">
            <button
              class="inline-block relative px-3 py-2 rounded-full flex items-center border border-brand-800 hover:bg-brand-100"
              @click="customizeAnother"
            >
              <div class="inline-block font-semibold text-xs">Customize another</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="ml-1 h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </ProductFooter>
  </ProductContainer>
</template>

<script>
import {
  ProductContainer,
  ProductText,
  ProductLabels,
  ProductAttachment,
  ProductPricing,
  ProductDescription,
  ProductBody,
  ProductAttachmentHero,
  ProductFooter,
  ProductVariant
} from '@/components'
import ProductNutritionalInformation from '@/components/Product/ProductNutritionalInformation'
import ProductDaySplit from '@/components/Product/ProductDaySplit'
import { useExpandProductHero } from '@/composables'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { ulid } from 'ulid'
import mapRelationship from '../../utils/mapRelationship'

export default {
  name: 'Product',

  components: {
    ProductContainer,
    ProductText,
    ProductLabels,
    ProductAttachment,
    ProductPricing,
    ProductDescription,
    ProductBody,
    ProductAttachmentHero,
    ProductFooter,
    ProductVariant,
    ProductDaySplit,
    ProductNutritionalInformation
  },

  props: {
    product: {
      required: true
    },
    submittableCartItem: {
      required: false
    },
    categoryProductStorePair: {
      required: false
    },
    inCart: {
      required: false
    }
  },

  setup(props) {
    const { isHeroExpanded, handleExpandHero } = useExpandProductHero()

    const store = useStore()
    const product = computed(() => store.state.objects.product[props.product?.id])

    return {
      product,
      handleExpandHero,
      isHeroExpanded
    }
  },

  data() {
    return { currentVariant: null, collapsed: true }
  },

  computed: {
    offerToCustomizeAnother() {
      return (
        (this.$store.getters.variants(this.product.attributes.id).length ||
          this.$store.getters.fulfillmentSlot?.attributes.allow_day_splits) &&
        this.submittableCartItem?.quantity > 0
      )
    },
    defaultEnlargedImage() {
      return this.categoryProductStorePair?.attributes?.product_card_style == 'enlarged-image'
    },
    submittableCartItemQuantity() {
      return this.submittableCartItem?.quantity
    },
    variants() {
      return this.$store.getters.variants(this.product?.attributes?.id)
    },
    variantsWithCustomerQuantities() {
      return this.variants.filter((variant) => {
        return variant.attributes?.quantity_option == 'custom_quantities'
      })
    },
    nutritionalInformation() {
      const combinedNutritionalInformation = this.submittableCartItem?.nutritional_information_combined

      return (
        combinedNutritionalInformation ||
        mapRelationship(this.$store.state, this.product?.relationships?.nutritional_information?.data)?.attributes
      )
    }
  },

  watch: {
    submittableCartItemQuantity(newValue, oldValue) {
      if (oldValue == 0) {
        this.currentVariant = null
        // if there are variantsWithCustomerQuantities, set the currentVariant to the first one's id
        if (this.variantsWithCustomerQuantities.length > 0) {
          this.currentVariant = this.variantsWithCustomerQuantities[0].attributes.id
        }
      }
    }
  },

  mounted() {
    if (this.defaultEnlargedImage && !this.inCart) {
      this.handleExpandHero()
    }
  },

  methods: {
    setCurrentVariant(id) {
      if (this.currentVariant === id) {
        this.currentVariant = null
      } else {
        this.currentVariant = id
      }
    },
    customizeAnother() {
      this.$store.dispatch('updateSubmittableCart', {
        submittableCartItem: this.freshSubmittableCartItem()
      })
    },
    freshSubmittableCartItem() {
      return {
        quantity: 1,
        frontend_key: ulid() + (this.inUpsell ? '-upsell' : ''),
        fulfillment_group_date: this.$store.state.splitDay,
        category_product_store_pair_id: this.submittableCartItem.category_product_store_pair_id,
        cart_item_variant_options_attributes: []
      }
    },
    collapse(e) {
      if (e.target.tagName !== 'A') this.collapsed = !this.collapsed
    }
  }
}
</script>

<template>
  <div class="mx-3 mb-2 text-gray-brand-700 text-sm" v-if="protein || totalFat || totalCarbs || calories">
    <div v-if="nutritionalInformation?.servings_per_container ?? 1 !== 1" class="italic">
      Nutritional information per serving (contains
      {{ nutritionalInformation?.servings_per_container }} servings)
    </div>
    <div class="flex gap-x-2">
      <div v-if="calories">
        <span class="font-semibold">{{ calories }}</span>
        <span class="ml-1">Calorie{{ calories === 1 ? '' : 's' }}</span>
      </div>
      <div v-if="totalFat">
        <span class="font-semibold">{{ totalFat }}g</span>
        <span class="ml-1 hidden lg:inline">Total</span>
        <span class="ml-1">Fats</span>
      </div>
      <div v-if="totalCarbs">
        <span class="font-semibold">{{ totalCarbs }}g</span>
        <span class="ml-1 hidden lg:inline">Total</span>
        <span class="ml-1">Carbs</span>
      </div>
      <div v-if="protein">
        <span class="font-semibold">{{ protein }}g</span>
        <span class="ml-1">Protein</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductNutritionalInformation',
  props: {
    nutritionalInformation: {
      type: Object,
      required: true
    }
  },
  computed: {
    protein() {
      return this.nutritionalInformation?.protein
    },
    calories() {
      return this.nutritionalInformation?.calories
    },
    totalFat() {
      return this.nutritionalInformation?.total_fat
    },
    totalCarbs() {
      return this.nutritionalInformation?.total_carbs
    }
  }
}
</script>
